// src/components/LoginPage.jsx
import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Divider,
  IconButton,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import { 
  signInWithEmailAndPassword, 
  signInWithPopup
} from 'firebase/auth';
import { auth, googleProvider, facebookProvider } from "../../firebase";

const LoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Email login successful');
      onLogin();
    } catch (error) {
      console.error('Email login failed:', error.message);
    }
  };

  const handleSocialLogin = async (provider) => {
    try {
      let authProvider;
      switch (provider) {
        case 'Google':
          authProvider = googleProvider;
          break;
        case 'Facebook':
          authProvider = facebookProvider;
          break;
        default:
          throw new Error('Unsupported provider');
      }

      await signInWithPopup(auth, authProvider);
      console.log(`${provider} login successful`);
      onLogin();
    } catch (error) {
      console.error(`${provider} login failed:`, error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleEmailLogin} noValidate sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            startIcon={<EmailIcon />}
          >
            Sign In with Email
          </Button>
        </Box>
        <Divider sx={{ width: '100%', my: 2 }}>OR</Divider>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          <IconButton
            onClick={() => handleSocialLogin('Google')}
            sx={{ backgroundColor: '#DB4437', color: 'white', '&:hover': { backgroundColor: '#C53929' } }}
          >
            <GoogleIcon />
          </IconButton>
          <IconButton
            onClick={() => handleSocialLogin('Facebook')}
            sx={{ backgroundColor: '#4267B2', color: 'white', '&:hover': { backgroundColor: '#365899' } }}
          >
            <FacebookIcon />
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;