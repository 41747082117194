import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import GetImage from '../ImageUploadComponent/get-image';
import './bookletsComponent.css';
import { useCart } from '../cart/cart-context';
import CustomizationPage from '../CustomizationPage/customization-page-component';
import { 
  Tabs, 
  Tab, 
  Typography, 
  Box, 
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Booklets() {
  const { name } = useParams();
  const [isCustomizing, setIsCustomizing] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [material, setMaterial] = useState('');
  const [size, setSize] = useState('');
  const { addToCart } = useCart();

  const booklets = [
    { name: 'Matte Book Uncoated Booklets', image: 'main_matte_book_8.5-5.5_uncoated_long.webp' },
    { name: 'Dull Book with Satin AQ Booklets', image: 'dull-book-w-satin-aq-booklets.webp' },
    { name: 'Direct Mail Booklets', image: 'direct-mail-booklets.webp' },
    { name: 'Premium Opaque Uncoated Booklets', image: 'premium-opaque-uncoated-booklets.webp' },
    { name: 'Gloss Booklets', image: 'gloss-booklet.webp' },
    { name: 'Gloss Cover with AQ Booklets', image: 'gloss-cover-w-aq-booklets.webp'},
    { name: 'Direct Mail Tri-Fold Flyers and Brochures', image: 'direct-mail-flyers-and-brochures.webp' },
    { name: 'Tri-Fold Brochures', image: 'tri-fold-flyers-brochures.webp'},
    { name: 'Direct Mail Flyers and Brochures Coated', image: 'direct-mail-flyers-and-brochures-coated.webp'}
  ];


  const card = booklets.find(c => c.name.toLowerCase().replace(/\s+/g, '-') === name);

  const handleCustomizeClick = () => {
    setIsCustomizing(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMaterialChange = (event) => {
    setMaterial(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleAddToCart = () => {
    if (card && material && size) {
      const item = {
        id: Date.now(),
        name: card.name,
        material,
        size,
        quantity: 1,
      };
      addToCart(item);
      alert('Item added to cart!');
    } else {
      alert('Please select material and size before adding to cart.');
    }
  };

  if (isCustomizing) {
    return (
      <CustomizationPage 
        productType="Booklets" 
        productName={card.name}
      />
    );
  }

  if (!card) {
    return null;
  }

  return (
    <main className="business-card-page">
      <div className="product-container">
        <div className='left-side'>
          <div className="image-gallery">
            <GetImage folderName="Booklets" imageName={card.image} />
          </div>
          <Paper sx={{ width: '100%' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="product info tabs">
              <Tab label="Description" />
              <Tab label="Specification" />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Typography>
                Make a lasting impression with our {card.name} from Sign Xpress.
              </Typography>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Typography>
                Specification details for {card.name}.
              </Typography>
            </TabPanel>
          </Paper>
        </div> 
        <div className="right-side">
          <div className="product-details">
            <Typography variant="h4" component="h2">{card.name}</Typography>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" component="h3">Customize Your Booklet:</Typography>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="material-label">Material</InputLabel>
                <Select
                  labelId="material-label"
                  id="material-select"
                  value={material}
                  label="Material"
                  onChange={handleMaterialChange}
                >
                  <MenuItem value="standard">Standard Paper</MenuItem>
                  <MenuItem value="premium">Premium Paper</MenuItem>
                  <MenuItem value="recycled">Recycled Paper</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="size-label">Size</InputLabel>
                <Select
                  labelId="size-label"
                  id="size-select"
                  value={size}
                  label="Size"
                  onChange={handleSizeChange}
                >
                  <MenuItem value="standard">Standard (3.5" x 2")</MenuItem>
                  <MenuItem value="square">Square (2.5" x 2.5")</MenuItem>
                  <MenuItem value="folded">Folded (3.5" x 4")</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={handleCustomizeClick}>
                Customize
              </Button>
              <Button variant="contained" color="secondary" onClick={handleAddToCart}>
                Add to Cart
              </Button>
            </Box>
          </div>
        </div> 
      </div>
    </main>
  );
}

export default Booklets;