import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import GetImage from '../ImageUploadComponent/get-image';
import './businesscard.css';
import { useCart } from '../cart/cart-context';
import CustomizationPage from '../CustomizationPage/customization-page-component';
import { 
  Tabs, 
  Tab, 
  Typography, 
  Box, 
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function BusinessCards() {
  const { name } = useParams();
  const [isCustomizing, setIsCustomizing] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [material, setMaterial] = useState('');
  const [size, setSize] = useState('');
  const { addToCart } = useCart();

  const businessCards = [
    { name: 'Standard Business Cards', image: 'standard-business-cards.webp' },
    { name: 'All-Inclusive Business Cards', image: 'all-inclusive-business-card.webp' },
    { name: 'Low Price Business Cards', image: 'low-price-business-card.webp' },
    { name: 'Suede Business Cards', image: 'suede-business-cards.webp' },
    { name: 'Silk Business Cards', image: 'silk-business-cards.webp' },
    { name: 'Raised Spot UV Business Cards', image: 'raised-spot-uv-business-cards.webp'},
    { name: 'Painted Edge Business Cards', image: 'painted-edge-business-cards.webp' },
    { name: 'Black Edge Business Cards', image: 'black-edge-business-cards.webp'},
    { name: 'Foil Worx Business Cards', image: 'foil-worx-business-cards.webp'},
    { name: 'Raised Foil Business Cards', image: 'raised-foil-business-cards.webp'},
    { name: 'Akuafoil Business Cards', image: 'akuafoil-business-cards.webp'},
    { name: 'Linen Uncoated Business Cards', image: 'linen-business-cards.webp'},
    { name: 'Brown Kraft Business Cards', image: 'brown-kraft-business-cards.webp'},
    { name: 'Natural Business Cards', image: 'natural-business-cards.webp'},
    { name: 'EndurACE Business Cards', image: 'endurace-business-cards.webp'},
    { name: 'Pearl Business Cards', image: 'pearl-business-cards.webp'},
    { name: 'Fold-over Business Cards', image: 'half-fold-business-cards.webp'},
    { name: 'Plastic Business Cards', image: 'plastic-business-cards.webp'},
    { name: 'Magnet Business Cards', image: 'magnet-business-cards.webp'},
    { name: 'Leaf Business Cards', image: 'leaf-business-cards.webp'},
    { name: 'Oval Business Cards', image: 'oval-business-cards.webp'},
    { name: 'Circle Business Cards', image: 'circle-business-cards.webp'},
  ];


  const card = businessCards.find(c => c.name.toLowerCase().replace(/\s+/g, '-') === name);

  const handleCustomizeClick = () => {
    setIsCustomizing(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMaterialChange = (event) => {
    setMaterial(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleAddToCart = () => {
    if (card && material && size) {
      const item = {
        id: Date.now(),
        name: card.name,
        material,
        size,
        quantity: 1,
      };
      addToCart(item);
      alert('Item added to cart!');
    } else {
      alert('Please select material and size before adding to cart.');
    }
  };

  if (isCustomizing) {
    return (
      <CustomizationPage 
        productType="Business Card" 
        productName={card.name}
      />
    );
  }

  if (!card) {
    return null;
  }

  return (
    <main className="business-card-page">
      <div className="product-container">
        <div className='left-side'>
          <div className="image-gallery">
            <GetImage folderName="BusinessCards" imageName={card.image} />
          </div>
          <Paper sx={{ width: '100%' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="product info tabs">
              <Tab label="Description" />
              <Tab label="Specification" />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Typography>
                Make a lasting impression with our {card.name} business cards from Sign Xpress.
              </Typography>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Typography>
                Specification details for {card.name} business cards.
              </Typography>
            </TabPanel>
          </Paper>
        </div> 
        <div className="right-side">
          <div className="product-details">
            <Typography variant="h4" component="h2">{card.name} Business Cards</Typography>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" component="h3">Customize Your Business Card:</Typography>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="material-label">Material</InputLabel>
                <Select
                  labelId="material-label"
                  id="material-select"
                  value={material}
                  label="Material"
                  onChange={handleMaterialChange}
                >
                  <MenuItem value="standard">Standard Paper</MenuItem>
                  <MenuItem value="premium">Premium Paper</MenuItem>
                  <MenuItem value="recycled">Recycled Paper</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="size-label">Size</InputLabel>
                <Select
                  labelId="size-label"
                  id="size-select"
                  value={size}
                  label="Size"
                  onChange={handleSizeChange}
                >
                  <MenuItem value="standard">Standard (3.5" x 2")</MenuItem>
                  <MenuItem value="square">Square (2.5" x 2.5")</MenuItem>
                  <MenuItem value="folded">Folded (3.5" x 4")</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={handleCustomizeClick}>
                Customize
              </Button>
              <Button variant="contained" color="secondary" onClick={handleAddToCart}>
                Add to Cart
              </Button>
            </Box>
          </div>
        </div> 
      </div>
    </main>
  );
}

export default BusinessCards;