import AWS from 'aws-sdk';

// Configure AWS
AWS.config.update({
  accessKeyId: 'AKIA5ASPFRSNIEGKQ5MQ',
  secretAccessKey: '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',
  region: 'us-east-2'
});

const ses = new AWS.SES({ apiVersion: '2010-12-01' });

export async function sendEmail(name, email, phone, message) {
  const customerParams = {
    Destination: {
      ToAddresses: [email] // Customer's email
    },
    Message: {
      Body: {
        Text: { Data: `Thank you for contacting us, ${name}. We've received your message and will get back to you soon.` }
      },
      Subject: { Data: "Thank you for your message" }
    },
    Source: "customer-info@signexpressus.com"
  };

  const managerParams = {
    Destination: {
      ToAddresses: ["dheerazpinnamaneni@outlook.com"] // Store manager's email
    },
    Message: {
      Body: {
        Text: { Data: `
          New contact form submission:
          Name: ${name}
          Email: ${email}
          Phone: ${phone}
          Message: ${message}
        ` }
      },
      Subject: { Data: "New Contact Form Submission" }
    },
    Source: "customer-info@signexpressus.com"
  };

  try {
    // Send email to customer
    await ses.sendEmail(customerParams).promise();
    
    // Send email to store manager
    await ses.sendEmail(managerParams).promise();

    return { success: true };
  } catch (error) {
    console.error('Error sending email:', error);
    return { success: false, error: error.message };
  }
}