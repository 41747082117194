import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import IpadContainer from "./components/ipad-container/ipad-container";
import Banner from "./components/banner/banner-component";
import BannerList from "./components/banner/banner-list-component";
import Shirts from "./components/shirts/shirts-component";
import ShirtsList from "./components/shirts/shirts-list-component";
import BusinessCards from "./components/businesscard/businesscardComponent";
import BusinessCardsList from "./components/businesscard/business-card-list-component";
import GreetingCardList from "./components/greetingCards/greetings-cards-list";
import GreetingCards from "./components/greetingCards/greetingCardComponent";
import AnnouncementCardList from "./components/announcementCards/announcement-cards-list";
import AnnouncementCards from "./components/announcementCards/announcementCardComponent";
import BrochuresList from "./components/brochures/brochures-list-component";
import Brochures from "./components/brochures/brochures-component";
import BookletsList from "./components/booklets/booklets-list"
import Booklets from "./components/booklets/booklets-componenet"
import Mugs from "./components/mugs/mugs-component";
import ImageUpload from "./components/ImageUploadComponent/image-upload-component";
import Cart from "./components/cart/cart-component";
import logo from "./round-logo.webp";
import CustomizationPage from "./components/CustomizationPage/customization-page-component";
import { CartProvider, useCart } from "./components/cart/cart-context";
import LoginPage from "./components/login/login-component";
import "./App.css";
import GetImage from "./components/ImageUploadComponent/get-image";
import { sendEmail } from "./utils/email-utli";

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0, 
    transition: { 
      duration: 0.6, 
      ease: "easeOut" 
    } 
  }
};


const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus("sending");

    try {
      const result = await sendEmail(formData.name, formData.email, formData.phone, formData.message);
      
      if (result.success) {
        setSubmitStatus("success");
        setFormData({ name: "", email: "", phone: "", message: "" });
      } else {
        setSubmitStatus("error");
        console.error("Failed to send email:", result.error);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setSubmitStatus("error");
    }
  };

  return (
    <motion.div className="contact-form" variants={fadeInUpVariants}>
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          required
          value={formData.name}
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          required
          value={formData.email}
          onChange={handleChange}
        />
        <input
          type="tel"
          name="phone"
          placeholder="Phone"
          value={formData.phone}
          onChange={handleChange}
        />
        <textarea
          name="message"
          placeholder="Message"
          required
          value={formData.message}
          onChange={handleChange}
        ></textarea>
        <button type="submit" disabled={submitStatus === "sending"}>
          {submitStatus === "sending" ? "Sending..." : "Send"}
        </button>
      </form>
      {submitStatus === "success" && (
        <p className="success-message">Message sent successfully!</p>
      )}
      {submitStatus === "error" && (
        <p className="error-message">Failed to send message. Please try again.</p>
      )}
    </motion.div>
  );
};

function AppContent() {
  const { cartItems } = useCart();
  const [isCartPopupVisible, setIsCartPopupVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const cartRef = useRef(null);
  const navigate = useNavigate();

  const toggleCartPopup = () => {
    setIsCartPopupVisible(!isCartPopupVisible);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setIsCartPopupVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cartRef]);

  const handleLogin = () => {
    setIsAuthenticated(true);
    navigate('/');
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    navigate('/');
  };

  const routes = [
    { path: "/banner/:name", element: <Banner /> },
    { path: "/bannerlist", element: <BannerList /> },
    { path: "/shirtslist", element: <ShirtsList /> },
    { path: "/shirts/:type", element: <Shirts /> },
    { path: "/business-card-list", element: <BusinessCardsList /> },
    { path: "/businessCards/:name", element: <BusinessCards /> },
    {path: "/greeting-cards-list", element: <GreetingCardList /> },
    { path: "/greetingCards/:name", element: <GreetingCards /> },
    { path: "/announcement-cards-list", element: <AnnouncementCardList /> },
    { path: "/announcementCards/:name", element: <AnnouncementCards /> },
    { path: "/brochures-list", element: <BrochuresList /> },
    { path: "/brochures/:name", element: <Brochures /> }, 
    { path: "/booklets-list", element: <BookletsList /> },
    { path: "/booklets/:name", element: <Booklets /> },        
    { path: "/mugs", element: <Mugs /> },
    { path: "/cart", element: <Cart /> },
    { path: "/customize/:type", element: <CustomizationPage /> },
    { path: "/image-upload", element: <ImageUpload /> },
    { path: "/login", element: <LoginPage onLogin={handleLogin} /> },
  ];

  return (
    <div className="App">
      <header className="header">
        <div className="header-content">
          <div className="header-title">
            <Link to="/">
              <img src={logo} className="logo" alt="SignExpress logo" />
            </Link>
          </div>
          <div className="header-links-container">
            <nav className="header-links">
              <Link to="/" className="header-link">Home</Link>
              <Link to="/bannerlist" className="header-link">Banners</Link>
              <Link to="/shirtslist" className="header-link">Shirts</Link>
              <Link to="/mugs" className="header-link">Mugs</Link>
              <Link to="/image-upload" className="header-link">ImageUpload</Link>
            </nav>
            <div className="cart" ref={cartRef}>
              <div className="cart-link" onClick={toggleCartPopup}>
                <span className="cart-icon">🛒</span>
              </div>
              {isCartPopupVisible && (
                <div className="cart-popup">
                  <Link to="/cart">
                    <button className="checkout-button">
                      Checkout ({cartItems.length})
                    </button>
                  </Link>
                </div>
              )}
            </div>
            {isAuthenticated ? (
              <button onClick={handleLogout} className="logout-button">Logout</button>
            ) : (
              <Link to="/login" className="login-button">Login</Link>
            )}
          </div>
        </div>
      </header>

      <main className="main-content">
        <Routes>
          <Route path="/" element={
            <>
              <motion.section 
                className="hero"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.3 }}
                variants={fadeInUpVariants}
              >
                <div className="hero-content">
                  <motion.h1 variants={fadeInUpVariants}>Welcome to Sign Express</motion.h1>
                  <motion.p variants={fadeInUpVariants}>Choose the item to start designing!</motion.p>
                </div>
                <motion.div className="ipad-container" variants={fadeInUpVariants}>
                  <IpadContainer />
                </motion.div>
              </motion.section>
              <motion.section
                className="facts"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.3 }}
                variants={fadeInUpVariants}              
              >
              <motion.h2 variants={fadeInUpVariants}>Fun Facts</motion.h2>
             <motion.div 
                className="facts-grid"
                variants={{
                      hidden: { opacity: 0 },
                      visible: { 
                      opacity: 1, 
                     transition: { 
                     staggerChildren: 0.2 
                   } 
                }
               }}
             >
    <motion.div className="fact-item" variants={fadeInUpVariants}>
      <h3>7,000+</h3>
      <p>Happy Clients</p>
    </motion.div>
    <motion.div className="fact-item" variants={fadeInUpVariants}>
      <h3>10,000+</h3>
      <p>Banners Sold</p>
    </motion.div>
    <motion.div className="fact-item" variants={fadeInUpVariants}>
      <h3>20,000+</h3>
      <p>Signs Produced</p>
    </motion.div>
    <motion.div className="fact-item" variants={fadeInUpVariants}>
      <h3>#1</h3>
      <p>Best in Dallas</p>
    </motion.div>
  </motion.div>                 
</motion.section>              <motion.section 
                className="clients"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.3 }}
                variants={fadeInUpVariants}
              >             
                <motion.h2 variants={fadeInUpVariants}>Our Clients</motion.h2>
                <motion.div 
                  className="client-grid"
                  variants={{
                    hidden: { opacity: 0 },
                    visible: { 
                      opacity: 1, 
                      transition: { 
                        staggerChildren: 0.2 
                      } 
                    }
                  }}
                >
                  <motion.div className="client-item" variants={fadeInUpVariants}>
                    <div className="client-logo">
                      <GetImage folderName="Clients" imageName="chloe-logo.webp" />
                    </div>
                    <h3>Chloe</h3>
                  </motion.div>
                  <motion.div className="client-item" variants={fadeInUpVariants}>
                    <div className="client-logo">
                    <GetImage folderName="Clients" imageName="stromboli-logo.webp" />
                    </div>
                    <h3>Stromboli</h3>
                  </motion.div>
                  <motion.div className="client-item" variants={fadeInUpVariants}>
                    <div className="client-logo">
                    <GetImage folderName="Clients" imageName="morgan-logo.webp" />
                    </div>
                    <h3>Morgan</h3>
                  </motion.div>
                </motion.div>
              </motion.section>

              <motion.section 
                className="about"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.3 }}
                variants={fadeInUpVariants}
              >
                <motion.h2 variants={fadeInUpVariants}>About SignExpress</motion.h2>
                <motion.p variants={fadeInUpVariants}>We specialize in custom printing solutions for businesses and individuals. With our state-of-the-art technology and dedicated team, we bring your ideas to life on various products.</motion.p>
              </motion.section>

              <motion.section 
                className="contact"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.3 }}
                variants={fadeInUpVariants}
              >
                <ContactForm />
                <motion.div className="contact-info" variants={fadeInUpVariants}>
                  <h2>Get in Touch</h2>
                  <p>Email: sarah@signdada.com</p>
                  <p>Phone: (972) 484-6366</p>
                  <p>Address: 11131 Denton Dr, Dallas, Tx 75229</p>
                </motion.div>
              </motion.section>
            </>
          } />
          {routes.map((route, index) => (
            <Route 
              key={index} 
              path={route.path} 
              element={route.element}
            />
          ))}
        </Routes>
      </main>

      <footer className="footer">
        <div className="footer-bottom">
          <p>&copy; 2024 SignExpress. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

function App() {
  return (
    <Router>
      <CartProvider>
        <AppContent />
      </CartProvider>
    </Router>
  );
}

export default App;