import React, { useState, useEffect } from "react";
import LeftSidebar from "./left-side-bar-component";
import DesignCanvas from "./design-canvas-component";
import TextEdit from "./text-edit";
import ImageUpload from "./image-upload";
import GraphicsList from "./graphics-list";
import QRCodeGenerator from "./qr-code-generator";
import "./customizationPage.css";
import GetImage from '../ImageUploadComponent/get-image';

function CustomizationPage({ productType, productName }) {
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [activePanel, setActivePanel] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);

  const DEFAULT_SIZE = {
    width: 10,
    height: 10,
  };

  

  const OFFSET_STEP = 5;

  useEffect(() => {
    if (productType && productName) {
      setBackgroundImage(<GetImage folderName='Customization' imageName={productName} />);
    }
  }, [productType, productName]);

  const addElement = (type) => {
    if (activePanel === type) {
      setActivePanel(null);
    } else {
      setActivePanel(type);
    }
    setSelectedElement(null);
  };

  const handleAddToCanvas = (type, src = "", textProperties = {}) => {
    const offset = elements.length * OFFSET_STEP;
    const newElement = {
      id: Date.now(),
      type,
      x: 25 + offset,
      y: 25 + offset,
      width: type === "text" ? 20 : DEFAULT_SIZE.width,
      height: type === "text" ? 10 : DEFAULT_SIZE.height,
      content: textProperties.content || "",
      fontFamily: textProperties.fontFamily || "Arial",
      fontSize: textProperties.fontSize || 16,
      fontWeight: textProperties.fontWeight || "normal",
      fontStyle: textProperties.fontStyle || "normal",
      color: textProperties.color || "#000000",
      src: type === "image" ? src : null,
    };
    setElements([...elements, newElement]);
    setSelectedElement(newElement);
    setActivePanel(null);
  };

  const handleElementSelect = (element) => {
    setSelectedElement(element);
    if (element.type === 'text') {
      setActivePanel('text');
    } else {
      setActivePanel(null);
    }
  };

  const handleElementModify = (modifiedElement) => {
    setElements((prevElements) =>
      prevElements.map((el) =>
        el.id === modifiedElement.id ? { ...el, ...modifiedElement } : el
      )
    );
    setSelectedElement(modifiedElement);
  };

  const handleElementRemove = (elementId) => {
    setElements((prevElements) =>
      prevElements.filter((el) => el.id !== elementId)
    );
    if (selectedElement && selectedElement.id === elementId) {
      setSelectedElement(null);
      setActivePanel(null);
    }
  };

  const handleImageUpload = (newImageElement) => {
    handleAddToCanvas("image", newImageElement.src);
  };

  const handlePreviewToggle = () => {
    setIsPreviewMode(!isPreviewMode);
    if (!isPreviewMode) {
      setSelectedElement(null);
      setActivePanel(null);
    }
  };

  const clearCanvas = () => {
    setElements([]);
    setSelectedElement(null);
    setActivePanel(null);
  };

  const handleGraphicSelect = (graphic) => {
    handleAddToCanvas("image", graphic.src);
  };

  const handleQRCodeGenerate = (qrCode) => {
    handleAddToCanvas("image", qrCode);
  };

  const renderActivePanel = () => {
    if (activePanel === 'text' && selectedElement && selectedElement.type === 'text') {
      return (
        <TextEdit
          element={selectedElement}
          updateElement={handleElementModify}
        />
      );
    }
    
    switch (activePanel) {
      case "text":
        return (
          <TextEdit
            onAdd={(textProperties) => handleAddToCanvas("text", "", textProperties)}
          />
        );
      case "image":
        return <ImageUpload onImageUpload={handleImageUpload} />;
      case "graphic":
        return <GraphicsList onGraphicSelect={handleGraphicSelect} />;
      case "qrcode":
        return <QRCodeGenerator onQRCodeGenerate={handleQRCodeGenerate} />;
      default:
        return null;
    }
  };

  return (
    <div className="customization-page">
      <div className="header">
        <div className="product-options">
          <button onClick={handlePreviewToggle}>
            {isPreviewMode ? "Exit Preview" : "Preview"}
          </button>
          <button onClick={clearCanvas}>Clear Canvas</button>
          {selectedElement && (
            <button onClick={() => handleElementRemove(selectedElement.id)}>
              Delete
            </button>
          )}
        </div>
      </div>
      <div className="main-content">
      
        <div className="canvas-and-panel">
          <LeftSidebar addElement={addElement} className="left-sidebar" />  
          {!isPreviewMode && (activePanel || selectedElement) && (
            <div className="active-panel">{renderActivePanel()}</div>
          )}
          <div className="canvas-wrapper">
            <DesignCanvas
              elements={elements}
              onElementSelect={handleElementSelect}
              onElementModify={handleElementModify}
              onElementRemove={handleElementRemove}
              isPreviewMode={isPreviewMode}
              backgroundImage={backgroundImage}
            />
          </div>
        </div>
      </div>
      {isPreviewMode && (
        <div className="preview-modal">
          <div className="preview-content">
            <DesignCanvas
              elements={elements}
              onElementSelect={() => {}}
              onElementModify={() => {}}
              onElementRemove={() => {}}
              isPreviewMode={true}
              backgroundImage={backgroundImage}
            />
          </div>
          <button className="close-preview" onClick={handlePreviewToggle}>
            Close Preview
          </button>
        </div>
      )}
    </div>
  );
}

export default CustomizationPage;