import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './BusinessCardsList.css';
import GetImage from '../ImageUploadComponent/get-image';

function BusinessCardList() {
  const navigate = useNavigate();

  const cardTypes = [
    { name: 'Standard Business Cards', image: 'standard-business-cards.webp' },
    { name: 'All-Inclusive Business Cards', image: 'all-inclusive-business-card.webp' },
    { name: 'Low Price Business Cards', image: 'low-price-business-card.webp' },
    { name: 'Suede Business Cards', image: 'suede-business-cards.webp' },
    { name: 'Silk Business Cards', image: 'silk-business-cards.webp' },
    { name: 'Raised Spot UV Business Cards', image: 'raised-spot-uv-business-cards.webp'},
    { name: 'Painted Edge Business Cards', image: 'painted-edge-business-cards.webp' },
    { name: 'Black Edge Business Cards', image: 'black-edge-business-cards.webp'},
    { name: 'Foil Worx Business Cards', image: 'foil-worx-business-cards.webp'},
    { name: 'Raised Foil Business Cards', image: 'raised-foil-business-cards.webp'},
    { name: 'Akuafoil Business Cards', image: 'akuafoil-business-cards.webp'},
    { name: 'Linen Uncoated Business Cards', image: 'linen-business-cards.webp'},
    { name: 'Brown Kraft Business Cards', image: 'brown-kraft-business-cards.webp'},
    { name: 'Natural Business Cards', image: 'natural-business-cards.webp'},
    { name: 'EndurACE Business Cards', image: 'endurace-business-cards.webp'},
    { name: 'Pearl Business Cards', image: 'pearl-business-cards.webp'},
    { name: 'Fold-over Business Cards', image: 'half-fold-business-cards.webp'},
    { name: 'Plastic Business Cards', image: 'plastic-business-cards.webp'},
    { name: 'Magnet Business Cards', image: 'magnet-business-cards.webp'},
    { name: 'Leaf Business Cards', image: 'leaf-business-cards.webp'},
    { name: 'Oval Business Cards', image: 'oval-business-cards.webp'},
    { name: 'Circle Business Cards', image: 'circle-business-cards.webp'},
  ];

  const handleCardsClick = (name) => {
    const urlFriendlyName = name.toLowerCase().replace(/\s+/g, '-');
    navigate(`/businessCards/${urlFriendlyName}`);
  };

  return (
    <motion.main
      className="cards-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className='h1'>Business Cards</h1>
      <motion.div className="cards-grid">
        {cardTypes.map((cards, index) => (
          <motion.div 
            key={cards.name}
            className="cards-item" 
            onClick={() => handleCardsClick(cards.name)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleCardsClick(cards.name);
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: index * 0.1,
              ease: "easeOut"
            }}
          >
            <div className="cards-image">
              <GetImage folderName="BusinessCards" imageName={cards.image} />
            </div>
            <div className="cards-info">
              <h2 className="cards-name">{cards.name}</h2>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.main>
  );
}

export default BusinessCardList;