import React, { useState, useEffect, useCallback } from "react";
import { ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { storage } from "../../firebase"; // Adjust this path to where your firebase.js file is located
import "./image-upload-component.css";

function ImageUploadComponent() {
    const [images, setImages] = useState([]);
    const [progress, setProgress] = useState({});
    const [uploadStatus, setUploadStatus] = useState({});
    const [uploadedImages, setUploadedImages] = useState([]);
    const [folders, setFolders] = useState([]);
    const [currentFolder, setCurrentFolder] = useState('');

    const fetchUploadedImages = useCallback(async () => {
        const rootRef = ref(storage, '');
        try {
            // Fetch folders
            const foldersResult = await listAll(rootRef);
            const fetchedFolders = foldersResult.prefixes.map(folderRef => folderRef.name);
            setFolders(fetchedFolders);

            // Fetch images for the current folder
            const imagesRef = ref(storage, currentFolder ? `${currentFolder}` : '');
            const result = await listAll(imagesRef);
            const urlPromises = result.items
                .filter(item => item.name !== '.folder') // Exclude the .folder placeholder
                .map(async (imageRef) => {
                    const url = await getDownloadURL(imageRef);
                    return { url, name: imageRef.name };
                });
            const images = await Promise.all(urlPromises);
            setUploadedImages(images);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [currentFolder]);

    useEffect(() => {
        fetchUploadedImages();
    }, [fetchUploadedImages]);

    const handleChange = (e) => {
        if (e.target.files.length > 0) {
            setImages(Array.from(e.target.files));
            setUploadStatus({});
            setProgress({});
        }
    };

    const handleUpload = () => {
        if (images.length === 0) return;

        images.forEach((image, index) => {
            const storageRef = ref(storage, currentFolder ? `${currentFolder}/${image.name}` : image.name);
            const uploadTask = uploadBytesResumable(storageRef, image);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(prev => ({ ...prev, [index]: progress }));
                },
                (error) => {
                    console.error(error);
                    setUploadStatus(prev => ({ ...prev, [index]: 'error' }));
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(`Image ${index + 1} Uploaded Successfully`);
                        setUploadStatus(prev => ({ ...prev, [index]: 'success' }));
                        fetchUploadedImages();
                    });
                }
            );
        });
    };

    const handleRetry = (index) => {
        setUploadStatus(prev => ({ ...prev, [index]: null }));
        setProgress(prev => ({ ...prev, [index]: 0 }));
    };

    const handleDelete = async (imageName) => {
        const imageRef = ref(storage, currentFolder ? `${currentFolder}/${imageName}` : imageName);
        try {
            await deleteObject(imageRef);
            console.log("Image deleted successfully");
            fetchUploadedImages();
        } catch (error) {
            console.error("Error deleting image:", error);
        }
    };

    const createFolder = async (folderName) => {
        if (folderName && !folders.includes(folderName)) {
            const newFolderRef = ref(storage, `${folderName}/.folder`);
            try {
                // Upload a small placeholder file to create the folder
                await uploadBytesResumable(newFolderRef, new Uint8Array([0]));
                console.log("Folder created successfully in Firebase");
                setFolders([...folders, folderName]);
                setCurrentFolder(folderName);
                // Clear the input field after creating the folder
                document.getElementById('newFolderName').value = '';
            } catch (error) {
                console.error("Error creating folder:", error);
            }
        }
    };

    return (
        <div className="image-upload-container">
            <div className="folder-management">
                <select value={currentFolder} onChange={(e) => setCurrentFolder(e.target.value)}>
                    <option value="">Root</option>
                    {folders.map((folder, index) => (
                        <option key={index} value={folder}>{folder}</option>
                    ))}
                </select>
                <input type="text" placeholder="New folder name" id="newFolderName" />
                <button onClick={() => createFolder(document.getElementById('newFolderName').value)}>
                    Create Folder
                </button>
            </div>
            <div className="image-upload">
                <input type="file" onChange={handleChange} multiple />
                <button onClick={handleUpload} disabled={images.length === 0}>Upload</button>
                {images.map((image, index) => (
                    <div key={index}>
                        <span>{image.name}</span>
                        {progress[index] > 0 && progress[index] < 100 && <progress value={progress[index]} max="100" />}
                        {uploadStatus[index] === 'success' && (
                            <span className="success-message">Uploaded successfully!</span>
                        )}
                        {uploadStatus[index] === 'error' && (
                            <span className="error-message">
                                Upload failed. <button onClick={() => handleRetry(index)}>Retry</button>
                            </span>
                        )}
                    </div>
                ))}
            </div>
            <div className="uploaded-images">
                <h3>Uploaded Images in {currentFolder || 'Root'}:</h3>
                <div className="image-grid">
                    {uploadedImages.map((image, index) => (
                    <div key={index} className="image-item">
                   <img src={image.url} alt={`Uploaded ${index + 1}`} />
                   <div className="image-name">{image.name}</div>
                <button 
                className="delete-button" 
                onClick={() => handleDelete(image.name)}
                >
                Delete
                </button>
                </div>
                ))}
            </div>
            </div>
        </div>
    );
}

export default ImageUploadComponent;