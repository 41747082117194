import React, { useState, useEffect } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";

function GetImage({ folderName, imageName }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      if (!folderName || !imageName) {
        setError("Folder name and image name are required");
        return;
      }

      try {
        const imageRef = ref(storage, `${folderName}/${imageName}`);
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
      } catch (err) {
        console.error("Error fetching image:", err);
        setError("Failed to load image");
      }
    };

    fetchImage();
  }, [folderName, imageName]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!imageUrl) {
    return <div>Loading...</div>;
  }

  return <img src={imageUrl} alt={imageName} style={{ maxWidth: '100%', height: 'auto' }} />;
}

export default GetImage;