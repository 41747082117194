import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './GreetingCardsList.css';
import GetImage from '../ImageUploadComponent/get-image';

function GreetingCardList() {
  const navigate = useNavigate();

  const cardTypes = [
    { name: 'Natural Greeting Cards', image: 'natural-greeting-cards.webp' },
    { name: 'Pearl Greeting Cards', image: 'pearl-greeting-cards.webp' },
    { name: 'Raised Foil Greeting Cards', image: 'raised-foil-greeting-cards.webp' },
    { name: 'Brown Kraft Greeting Cards', image: 'brown-kraft-greeting-cards.webp' },
    { name: 'Raised Spot UV Greeting Cards', image: 'raised-spot-uv-greeting-cards.webp' },
    { name: 'Suede Greeting Cards', image: 'suede-greeting-cards.webp'},
    { name: 'Akuafoil Greeting Cards', image: 'akuafoil-greeting-cards.webp' },
    { name: '100lb Cover Linen Greeting Cards', image: '100lb-cover-linen-greeting-cards.webp'},
    { name: '100lb Gloss Cover Greeting Cards', image: '100lb-gloss-cover-greeting-cards.webp'},
    { name: 'Silk Greeting Cards', image: 'silk-greeting-cards.webp'},
    { name: 'Standard Greeting Cards', image: 'standard-card-stocks-greeting-cards.webp'},
    { name: 'Cards with Gift Card Holder (Slits) Greeting Cards', image: 'gift-cards-holder-greeting-cards.webp'}
  ];

  const handleCardsClick = (name) => {
    const urlFriendlyName = name.toLowerCase().replace(/\s+/g, '-');
    navigate(`/greetingCards/${urlFriendlyName}`);
  };

  return (
    <motion.main
      className="cards-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className='h1'>Greeting Cards</h1>
      <motion.div className="cards-grid">
        {cardTypes.map((cards, index) => (
          <motion.div 
            key={cards.name}
            className="cards-item" 
            onClick={() => handleCardsClick(cards.name)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleCardsClick(cards.name);
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: index * 0.1,
              ease: "easeOut"
            }}
          >
            <div className="cards-image">
              <GetImage folderName="Greeting Cards" imageName={cards.image} />
            </div>
            <div className="cards-info">
              <h2 className="cards-name">{cards.name}</h2>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.main>
  );
}

export default GreetingCardList;