import React, { useState, useEffect } from "react";
import { 
  TextField, 
  Select, 
  MenuItem, 
  ToggleButton, 
  ToggleButtonGroup, 
  InputLabel, 
  FormControl,
  Button,
  Box,
  Grid,
} from "@mui/material";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import SubscriptIcon from '@mui/icons-material/Subscript';

function TextEdit({ element, onAdd, updateElement }) {
  const [text, setText] = useState("");
  const [fontFamily, setFontFamily] = useState("Arial");
  const [fontSize, setFontSize] = useState(16);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [verticalAlign, setVerticalAlign] = useState("baseline");
  const [color, setColor] = useState("#000000");

  useEffect(() => {
    if (element) {
      setText(element.content || "");
      setFontFamily(element.fontFamily || "Arial");
      setFontSize(element.fontSize || 16);
      setIsBold(element.fontWeight === 'bold');
      setIsItalic(element.fontStyle === 'italic');
      setIsUnderline(element.textDecoration?.includes('underline') || false);
      setIsStrikethrough(element.textDecoration?.includes('line-through') || false);
      setVerticalAlign(element.verticalAlign || "baseline");
      setColor(element.color || "#000000");
    }
  }, [element]);

  const updateTextProperty = (updates) => {
    const updatedElement = { ...element, ...updates };
    if (updateElement) {
      updateElement(updatedElement);
    }
  };

  const handleChange = (property) => (event) => {
    const value = event.target.value;
    switch(property) {
      case 'content':
        setText(value);
        updateTextProperty({ content: value });
        break;
      case 'fontFamily':
        setFontFamily(value);
        updateTextProperty({ fontFamily: value });
        break;
      case 'fontSize':
        setFontSize(value);
        updateTextProperty({ fontSize: parseInt(value) });
        break;
      case 'color':
        setColor(value);
        updateTextProperty({ color: value });
        break;
      default:
        break;
    }
  };

  const handleStyleChange = (property) => (event, newValue) => {
    switch(property) {
      case 'bold':
        setIsBold(newValue);
        updateTextProperty({ fontWeight: newValue ? 'bold' : 'normal' });
        break;
      case 'italic':
        setIsItalic(newValue);
        updateTextProperty({ fontStyle: newValue ? 'italic' : 'normal' });
        break;
      case 'underline':
        setIsUnderline(newValue);
        updateTextProperty({ 
          textDecoration: getTextDecoration(newValue, isStrikethrough)
        });
        break;
      case 'strikethrough':
        setIsStrikethrough(newValue);
        updateTextProperty({ 
          textDecoration: getTextDecoration(isUnderline, newValue)
        });
        break;
      case 'verticalAlign':
        if (newValue !== null) {
          setVerticalAlign(newValue);
          updateTextProperty({ verticalAlign: newValue });
        }
        break;
      default:
        break;
    }
  };

  const getTextDecoration = (underline, strikethrough) => {
    if (underline && strikethrough) return 'underline line-through';
    if (underline) return 'underline';
    if (strikethrough) return 'line-through';
    return 'none';
  };

  const handleAdd = () => {
    onAdd({
      content: text,
      fontFamily,
      fontSize: parseInt(fontSize),
      fontWeight: isBold ? 'bold' : 'normal',
      fontStyle: isItalic ? 'italic' : 'normal',
      textDecoration: getTextDecoration(isUnderline, isStrikethrough),
      verticalAlign,
      color
    });
    setText("");
  };

  return (
    <Box className="text-edit" sx={{ '& > *': { mb: 2.5 } }}> {/* Added margin bottom to all direct children */}
      <TextField 
        fullWidth
        label="Text"
        variant="outlined"
        value={text}
        onChange={handleChange('content')}
      />
      
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Font Family</InputLabel>
            <Select
              value={fontFamily}
              onChange={handleChange('fontFamily')}
              label="Font Family"
            >
              <MenuItem value="Arial">Arial</MenuItem>
              <MenuItem value="Helvetica">Helvetica</MenuItem>
              <MenuItem value="Times New Roman">Times New Roman</MenuItem>
              <MenuItem value="Courier New">Courier New</MenuItem>
              <MenuItem value="Verdana">Verdana</MenuItem>
              <MenuItem value="Georgia">Georgia</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            type="number"
            label="Font Size"
            value={fontSize}
            onChange={handleChange('fontSize')}
            inputProps={{ min: "8", max: "72" }}
          />
        </Grid>
      </Grid>
      
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <ToggleButtonGroup
            value={isBold ? 'bold' : 'normal'}
            exclusive
            onChange={handleStyleChange('bold')}
          >
            <ToggleButton value="bold" aria-label="bold">
              <FormatBoldIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={isItalic ? 'italic' : 'normal'}
            exclusive
            onChange={handleStyleChange('italic')}
          >
            <ToggleButton value="italic" aria-label="italic">
              <FormatItalicIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={isUnderline ? 'underline' : 'none'}
            exclusive
            onChange={handleStyleChange('underline')}
          >
            <ToggleButton value="underline" aria-label="underline">
              <FormatUnderlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={isStrikethrough ? 'line-through' : 'none'}
            exclusive
            onChange={handleStyleChange('strikethrough')}
          >
            <ToggleButton value="line-through" aria-label="strikethrough">
              <StrikethroughSIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={verticalAlign}
            exclusive
            onChange={handleStyleChange('verticalAlign')}
          >
            <ToggleButton value="baseline" aria-label="normal">
              T
            </ToggleButton>
            <ToggleButton value="super" aria-label="superscript">
              <SuperscriptIcon />
            </ToggleButton>
            <ToggleButton value="sub" aria-label="subscript">
              <SubscriptIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      
      <Box>
        <TextField
          type="color"
          label="Color"
          value={color}
          onChange={handleChange('color')}
          sx={{ width: '100px' }}
        />
      </Box>
      
      {!updateElement && (
        <Box mt={2}>
          <Button variant="contained" onClick={handleAdd}>Add to Canvas</Button>
        </Box>
      )}
    </Box>
  );
}

export default TextEdit;