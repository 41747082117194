import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { SketchPicker } from 'react-color';
import './QRCodeGenerator.css';

function QRCodeGenerator({ onQRCodeGenerate }) {
  const [qrValue, setQrValue] = useState('');
  const [fgColor, setFgColor] = useState('#000000');
  const [qrStyle, setQrStyle] = useState('squares');
  const [showFgPicker, setShowFgPicker] = useState(false);

  const handleGenerate = () => {
    if (qrValue) {
      const canvas = document.getElementById('qrCode');
      const pngUrl = canvas.toDataURL('image/png');
      onQRCodeGenerate(pngUrl);
    }
  };

  const qrStyles = ['squares', 'dots'];

  return (
    <div className="qr-code-generator">
      <h3>Generate QR Code</h3>
      <input
        type="text"
        value={qrValue}
        onChange={(e) => setQrValue(e.target.value)}
        placeholder="Enter text or URL"
      />
      <div className="color-pickers">
        <div>
          <button onClick={() => setShowFgPicker(!showFgPicker)}>
            Color
          </button>
          {showFgPicker && (
            <SketchPicker
              color={fgColor}
              onChangeComplete={(color) => setFgColor(color.hex)}
            />
          )}
        </div>
      </div>
      <div className="qr-style-selector">
        <label>QR Style:</label>
        <select value={qrStyle} onChange={(e) => setQrStyle(e.target.value)}>
          {qrStyles.map((style) => (
            <option key={style} value={style}>
              {style.charAt(0).toUpperCase() + style.slice(1)}
            </option>
          ))}
        </select>
      </div>
      <button onClick={handleGenerate}>Generate</button>
      <div className="qr-preview">
        <QRCode
          id="qrCode"
          value={qrValue}
          size={200}
          level="H"
          fgColor={fgColor}
          renderAs="canvas"
          imageSettings={{
            src: "/path/to/image.png",
            x: null,
            y: null,
            height: 24,
            width: 24,
            excavate: true,
          }}
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    </div>
  );
}

export default QRCodeGenerator;