import React from 'react';
import { useCart } from './cart-context';
import './cart.css';
import { sendEmail } from '../../utils/email-utli';

function Cart() {
  const { cartItems, removeFromCart, addToCart } = useCart();

  const getTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleRemoveFromCart = (itemId) => {
    removeFromCart(itemId);
  };

  const handlePurchase = async () => {
    if (cartItems.length === 0) {
      alert("Your cart is empty. Please add items before purchasing.");
      return;
    }

    const isConfirmed = window.confirm("Are you sure you want to complete this purchase?");
    
    if (isConfirmed) {
      // Prepare order summary
      const orderSummary = cartItems.map(item => 
        `${item.name} x ${item.quantity} - $${(item.price * item.quantity).toFixed(2)}`
      ).join('\n');
      const totalPrice = getTotalPrice().toFixed(2);

      // Send email to customer
      const customerEmailResult = await sendEmail(
        "Siva Pinnamaneni", // Replace with actual customer name if available
        "sivagangadheeraz@gmail.com", // Replace with actual customer email
        "", // Phone not needed for this email
        `Your order summary:\n\n${orderSummary}\n\nTotal: $${totalPrice}`
      );

      // Send email to manager
      const managerEmailResult = await sendEmail(
        "Manager",
        "dheerazpinnamaneni@outlook.com",
        "",
        `New order received:\n\n${orderSummary}\n\nTotal: $${totalPrice}`
      );

      if (customerEmailResult.success && managerEmailResult.success) {
        // Clear the cart by removing each item
        cartItems.forEach(item => removeFromCart(item.id));

        // Show thank you message
        alert("Thank you for your purchase! Please check your email for order confirmation.");
      } else {
        alert("There was an error processing your order. Please try again later.");
        console.error("Email sending failed:", customerEmailResult.error || managerEmailResult.error);
      }
    }
  };

  // Mock data for frequently bought together items
  const frequentlyBoughtTogether = [
    { id: 'fbt1', name: 'Item 1', price: 9.99 },
    { id: 'fbt2', name: 'Item 2', price: 14.99 },
    { id: 'fbt3', name: 'Item 3', price: 19.99 },
  ];

  return (
    <div className="cart-container">
      <h2>Shopping Cart</h2>
      
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div className="cart-layout">
          <div className="cart-items">
            <ul>
              {cartItems.map((item) => (
                <li key={item.id}>
                  <span>{item.name} x {item.quantity}</span>
                  <button onClick={() => handleRemoveFromCart(item.id)}>Remove</button>
                </li>
              ))}
            </ul>
          </div>
          <div className="cart-prices">
            <ul>
              {cartItems.map((item) => (
                <li key={item.id}>
                  ${(item.price * item.quantity).toFixed(2)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      
      <div className="cart-summary">
        <p>Total items: {getTotalItems()}</p>
        <p>Total price: ${getTotalPrice().toFixed(2)}</p>
        <button className="purchase-button" onClick={handlePurchase}>Purchase</button>
      </div>

      <div className="frequently-bought-together">
        <h3>Frequently Bought Together</h3>
        <ul>
          {frequentlyBoughtTogether.map((item) => (
            <li key={item.id}>
              <span>{item.name}</span>
              <span>${item.price.toFixed(2)}</span>
              <button onClick={() => addToCart(item)}>Add to Cart</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Cart;