import React from 'react';

function ImageUpload({ onImageUpload }) {
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        let elementWidth, elementHeight;
        
        if (aspectRatio > 1) {
          elementWidth = 30; // 30% of canvas width
          elementHeight = 30 / aspectRatio;
        } else {
          elementHeight = 30; // 30% of canvas height
          elementWidth = 30 * aspectRatio;
        }

        const newImageElement = {
          id: Date.now(),
          type: 'image',
          x: 35, // Centered horizontally
          y: 35, // Centered vertically
          width: elementWidth,
          height: elementHeight,
          src: e.target.result,
        };
        onImageUpload(newImageElement);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} accept="image/*" />
    </div>
  );
}

export default ImageUpload;