import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import GetImage from '../ImageUploadComponent/get-image';
import CustomizationPage from '../CustomizationPage/customization-page-component';
import './shirts.css';

function Shirts() {
  const [selectedSize, setSelectedSize] = useState('M');
  const [selectedColor, setSelectedColor] = useState('white');
  const [isCustomizing, setIsCustomizing] = useState(false);

  const shirtImages = [
    't-shirt1.jpg',
    't-shirt2.jpg',
    't-shirt3.jpg',
    't-shirt4.jpg',
    't-shirt5.jpg',
    'tsg.jpg'
  ];

  const handleCustomizeClick = () => {
    setIsCustomizing(true);
  };

  if (isCustomizing) {
    return (
      <CustomizationPage 
        productType="Banner" 
        productName={'white-tee.webp'}
      />
    );
  }

  const sizes = ['S', 'M', 'L', 'XL'];
  const colors = ['White', 'Black', 'Gray', 'Red', 'Blue'];

  return (
    <main className="shirts-page">
      <div className="product-container">
        <div className="product-media">
          <div className="thumbnail-gallery">
            <Carousel showThumbs={true} showArrows={true} showStatus={true} className="carousel">
              {shirtImages.map((image, index) => (
                <div key={index}>
                  <GetImage folderName="Shirts" imageName={image} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        <div className="product-details">
          <h2>SignExpress T Shirts</h2>
          <h3>Options:</h3>
          <div className="option">
            <label htmlFor="size">Size:</label>
            <select id="size" value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
              {sizes.map(size => (
                <option key={size} value={size}>{size}</option>
              ))}
            </select>
          </div>
          <div className="option">
            <label htmlFor="color">Color:</label>
            <select id="color" value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)}>
              {colors.map(color => (
                <option key={color} value={color}>{color}</option>
              ))}
            </select>
          </div>

          <div className="buttons">
            <button onClick={handleCustomizeClick}>Customize</button>
            <button className="add-to-cart">Add to Cart</button>
          </div>
        </div>
      </div>

      <div className="product-description">
        <h2>Product Description</h2>
        <p className="description">
          Branded, short sleeve t-shirts keep activities organized, raise brand awareness, and provide a cohesive look all with on-the-move marketing! Practical and comfortable, these 100% cotton t-shirts last well beyond most campaign timeframes and offer a lasting, tangible memory of a successful event that can be worn over and over again. They work well as gifts and giveaways, freebies in bundled products, uniforms, same-team identifiers, or even commemorative souvenirs!
        </p>
      </div>
    </main>
  );
}

export default Shirts;