// src/firebase.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyArhuxolihKVX7GtxAoGfbqozY-vAhTDY8",
    authDomain: "signexpress-c49f6.firebaseapp.com",
    projectId: "signexpress-c49f6",
    storageBucket: "signexpress-c49f6.appspot.com",
    messagingSenderId: "782242818577",
    appId: "1:782242818577:web:fd44bafdf101b4fb6edfb0",
    measurementId: "G-W30K5CTC5P",
    webClientId: "782242818577-cg8drar7snbncqetpankvo2s4ag2n9j4.apps.googleusercontent.com"
  };

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export { storage, auth, googleProvider, facebookProvider };