import React from "react";
import { Grid, Tooltip } from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ImageIcon from "@mui/icons-material/Image";
import BrushIcon from "@mui/icons-material/Brush";
import QrCodeIcon from "@mui/icons-material/QrCode";
import "./left-side-bar-component.css";

function LeftSidebar({ addElement }) {
  const icons = [
    { name: "Text", icon: TextFieldsIcon, action: () => addElement("text") },
    { name: "Images", icon: ImageIcon, action: () => addElement("image") },
    { name: "Graphics", icon: BrushIcon, action: () => addElement("graphic") },
    { name: "QR Codes", icon: QrCodeIcon, action: () => addElement("qrcode") },
  ];

  return (
    <div className="left-sidebar">
      <Grid container direction="column" spacing={2}>
        {icons.map((item, index) => (
          <Grid item key={index}>
            <Tooltip title={item.name} placement="right">
              <div
                onClick={item.action}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <item.icon
                  sx={{
                    fontSize: 40,
                    color: "primary.main",
                    "&:hover": { color: "primary.dark" },
                  }}
                />
                <div style={{ marginTop: "4px", fontSize: "12px" }}>
                  {item.name}
                </div>
              </div>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default LeftSidebar;
