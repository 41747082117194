import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './AnnouncementCardsList.css';
import GetImage from '../ImageUploadComponent/get-image';

function AnnouncementCardList() {
  const navigate = useNavigate();

  const cardTypes = [
    { name: 'Magnet Announcement Cards', image: 'magnet-announcement-cards.webp' },
    { name: 'Black Edge Announcement Cards', image: 'black-edge-announcement-cards.webp' },
    { name: 'Silk Announcement Cards', image: 'silk-announcement-cards.webp' },
    { name: 'Pearl Announcement Cards', image: 'pearl-announcement-cards.webp' },
    { name: 'Natural Announcement Cards', image: 'natural-announcement-cards.webp' },
    { name: 'Akuafoil Announcement Cards', image: 'akuafoil-announcement-cards.webp'},
    { name: 'Brown Kraft Announcement Cards', image: 'brown-kraft-announcement-cards.webp' },
    { name: 'Suede Announcement Cards', image: 'suede-announcement-cards.webp'},
    { name: 'Round Corner Announcement Cards', image: 'round-corner-announcement-cards.webp'},
    { name: 'Standard Announcement Cards', image: 'standard-announcement-cards.webp'},
    { name: 'Painted Edge Announcement Cards', image: '4over-announcement-painted-edge-r-020623.webp'}
  ];

  const handleCardsClick = (name) => {
    const urlFriendlyName = name.toLowerCase().replace(/\s+/g, '-');
    navigate(`/announcementCards/${urlFriendlyName}`);
  };

  return (
    <motion.main
      className="cards-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className='h1'>Announcement Cards</h1>
      <motion.div className="cards-grid">
        {cardTypes.map((cards, index) => (
          <motion.div 
            key={cards.name}
            className="cards-item" 
            onClick={() => handleCardsClick(cards.name)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleCardsClick(cards.name);
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: index * 0.1,
              ease: "easeOut"
            }}
          >
            <div className="cards-image">
              <GetImage folderName="Announcement Cards" imageName={cards.image} />
            </div>
            <div className="cards-info">
              <h2 className="cards-name">{cards.name}</h2>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.main>
  );
}

export default AnnouncementCardList;