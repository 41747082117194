import React from 'react';
import { useNavigate } from 'react-router-dom';
import './banner.css';
import GetImage from '../ImageUploadComponent/get-image';

function BannerList() {
  const navigate = useNavigate();

  const bannerTypes = [
    { name: 'Scrim Vinyl Banners', image: 'scrim-vinyl-banner.jpg', type: 'Outdoor' },
    { name: 'Mesh Banners', image: 'mesh-banner.jpg', type: 'Outdoor' },
    { name: 'Premium Polyester Banners', image: 'premium-polyester-banners.webp', type: 'Indoor' },
    { name: 'Blockout Vinyl Single-Sided Banner', image: 'blackout-vinyl-single-sided-banner.webp', type: 'Indoor' },
    { name: 'Double-Sided Blockout Vinyl Banners', image: 'blockout-vinyl-banners-doublesided.webp', type: 'Indoor' },
    { name: 'Premium Vinyl Banners', image: 'premium-vinyl-banner.jpg', type: 'Indoor' },
    { name: 'Artist Canvas', image: 'artist-canvas-banners.webp', type: 'Indoor' },
  ];

  const handleBannerClick = (name) => {
    const urlFriendlyName = name.toLowerCase().replace(/\s+/g, '-');
    navigate(`/banner/${urlFriendlyName}`);
  };

  return (
    <main className="banner-page">
      <h1>Banners & Flags</h1>
      <div className="banner-grid">
        {bannerTypes.map((banner) => (
          <div 
            key={banner.name}
            className="banner-item" 
            onClick={() => handleBannerClick(banner.name)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleBannerClick(banner.name);
            }}
          >
            <div className="banner-image">
              <GetImage folderName="Banners" imageName={banner.image} />
            </div>
            <div className="banner-info">
              <h2 className="banner-name">{banner.name}</h2>
              <p className="banner-type">{banner.type}</p>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
}

export default BannerList;