import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './banner.css';
import GetImage from '../ImageUploadComponent/get-image';
import { useCart } from '../cart/cart-context';
import CustomizationPage from '../CustomizationPage/customization-page-component';
import { 
  Tabs, 
  Tab, 
  Typography, 
  Box, 
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Banner() {
  const { name } = useParams();
  const [isCustomizing, setIsCustomizing] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [material, setMaterial] = useState('');
  const [size, setSize] = useState('');
  const [customSize, setCustomSize] = useState('');
  const { addToCart } = useCart();

  const bannerTypes = [
    { name: 'Scrim Vinyl Banners', image: 'scrim-vinyl-banner.jpg', type: 'Outdoor' },
    { name: 'Mesh Banners', image: 'mesh-banner.jpg', type: 'Outdoor' },
    { name: 'Premium Polyester Banners', image: 'premium-polyester-banners.webp', type: 'Indoor' },
    { name: 'Blockout Vinyl Single-Sided Banner', image: 'blackout-vinyl-single-sided-banner.webp', type: 'Indoor' },
    { name: 'Double-Sided Blockout Vinyl Banners', image: 'blockout-vinyl-banners-doublesided.webp', type: 'Indoor' },
    { name: 'Premium Vinyl Banners', image: 'premium-vinyl-banner.jpg', type: 'Indoor' },
    { name: 'Artist Canvas', image: 'artist-canvas-banners.webp', type: 'Indoor' },
  ];

  const banner = bannerTypes.find(b => b.name.toLowerCase().replace(/\s+/g, '-') === name);

  const handleCustomizeClick = () => {
    setIsCustomizing(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMaterialChange = (event) => {
    setMaterial(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
    if (event.target.value !== 'custom') {
      setCustomSize('');
    }
  };

  const handleAddToCart = () => {
    if (banner && material && (size !== 'custom' ? size : customSize)) {
      const item = {
        id: Date.now(),
        name: banner.name,
        material,
        size: size === 'custom' ? customSize : size,
        quantity: 1,
      };
      addToCart(item);
      alert('Item added to cart!');
    } else {
      alert('Please select material and size before adding to cart.');
    }
  };

  if (isCustomizing) {
    return (
      <CustomizationPage 
        productType="Banner" 
        productName={banner.image}
      />
    );
  }

  if (!banner) {
    return null;
  }

  return (
    <main className="banner-page">
      <div className="product-container">
        <div className='left-side'>
          <div className="image-gallery">
            <GetImage folderName="Banners" imageName={banner.image} />
          </div>
          <Paper sx={{ width: '100%' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="product info tabs">
              <Tab label="Description" />
              <Tab label="Specification" />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Typography>
                Promote, advertise, or make announcements with {banner.name} from Sign Xpress.
              </Typography>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Typography>
                {/* Add specification details here */}
                Specification details for {banner.name}.
              </Typography>
            </TabPanel>
          </Paper>
        </div> 
        <div className="right-side">
          <div className="product-details">
            <Typography variant="h4" component="h2">{banner.name}</Typography>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" component="h3">Customize Your Banner:</Typography>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="material-label">Material</InputLabel>
                <Select
                  labelId="material-label"
                  id="material-select"
                  value={material}
                  label="Material"
                  onChange={handleMaterialChange}
                >
                  <MenuItem value="vinyl">Vinyl</MenuItem>
                  <MenuItem value="fabric">Fabric</MenuItem>
                  <MenuItem value="mesh">Mesh</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="size-label">Size</InputLabel>
                <Select
                  labelId="size-label"
                  id="size-select"
                  value={size}
                  label="Size"
                  onChange={handleSizeChange}
                >
                  <MenuItem value="small">Small (2' x 4')</MenuItem>
                  <MenuItem value="medium">Medium (3' x 6')</MenuItem>
                  <MenuItem value="large">Large (4' x 8')</MenuItem>
                  <MenuItem value="custom">Custom Size</MenuItem>
                </Select>
              </FormControl>

              {size === 'custom' && (
                <TextField
                  fullWidth
                  sx={{ mt: 2 }}
                  label="Enter custom size"
                  variant="outlined"
                  value={customSize}
                  onChange={(e) => setCustomSize(e.target.value)}
                  placeholder="e.g., 5' x 10'"
                />
              )}
            </Box>

            <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={handleCustomizeClick}>
                Customize
              </Button>
              <Button variant="contained" color="secondary" onClick={handleAddToCart}>
                Add to Cart
              </Button>
            </Box>
          </div>
        </div> 
      </div>
    </main>
  );
}

export default Banner;