import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './mugs.css'; 

// Import your shirt images (adjust paths as needed)
import mug1  from './mug1.jpg';
import mug2  from './mug2.jpg';
import mug3  from './mug3.jpg';
import mug4  from './mug4.jpg';
import mug5  from './mug5.png';
import mug6  from './mug6.jpg';

function Mugs() {
  const [setSelectedImage] = useState(mug1);
  const [selectedSize, setSelectedSize] = useState('M');
  const [selectedColor, setSelectedColor] = useState('white');
  const [selectedStock, setSelectedStock] = useState('150z White Ceramic Mug');

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const sizes = ['S', 'M', 'L', 'XL']; 
  const colors = ['White', 'Black', 'Gray', 'Red', 'Blue'];
  const stock = ['150z White Ceramic Mug', '1oz White Ceramic Mug'];

  return (
    <main className="mugs-page">
      <div className="product-container">
        <div className="product-media">
          <div className="thumbnail-gallery">
            <Carousel showThumbs={true} showArrows={true} showStatus={false} className="carousel">
              {[mug1, mug2, mug3, mug4, mug5, mug6].map((image, index) => (
                <div key={index} onClick={() => handleImageClick(image)}>
                  <img src={image} alt={`Shirt ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        <div className="product-details">
          <h2>SignExpress Mugs</h2>
          {/* ... your existing description ... */}

          <h3>Options:</h3>
          <div className="option">
            <label htmlFor="size">Size:</label>
            <select id="size" value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
              {sizes.map(size => (
                <option key={size} value={size}>{size}</option>
              ))}
            </select>
          </div>
          <div className="option">
            <label htmlFor="stock">Stock:</label>
            <select id="stock" value={selectedStock} onChange={(e) => setSelectedStock(e.target.value)}>
              {stock.map(stock => (
                <option key={stock} value={stock}>{stock}</option>
              ))}
            </select>
          </div>          
          <div className="option">
            <label htmlFor="color">Color:</label>
            <select id="color" value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)}>
              {colors.map(color => (
                <option key={color} value={color}>{color}</option>
              ))}
            </select>
          </div>
         <div className="buttons">
         <Link to="/customize/mugs" className="customize-button">Customize</Link>

          <button className="add-to-cart">Add to Cart</button>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Mugs;
