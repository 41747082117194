import React, { useState, useRef, useEffect } from "react";
import "./DesignCanvas.css";

const DesignCanvas = ({
  elements,
  onElementSelect,
  onElementModify,
  onElementRemove,
  isPreviewMode,
  backgroundImage,
}) => {
  const canvasRef = useRef(null);
  const [dragging, setDragging] = useState(null);
  const [resizing, setResizing] = useState(null);

  // Fixed canvas size
  const CANVAS_WIDTH = 600;
  const CANVAS_HEIGHT = 600;

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (dragging) {
        const canvas = canvasRef.current.getBoundingClientRect();
        const newX = ((e.clientX - canvas.left) / canvas.width) * 100;
        const newY = ((e.clientY - canvas.top) / canvas.height) * 100;

        onElementModify({
          ...dragging.element,
          x: Math.max(0, Math.min(newX, 100)),
          y: Math.max(0, Math.min(newY, 100)),
        });
      } else if (resizing) {
        const canvas = canvasRef.current.getBoundingClientRect();
        let { x, y, width, height } = resizing.element;
        const aspectRatio = width / height;

        if (resizing.direction.includes("e")) {
          width = ((e.clientX - canvas.left) / canvas.width) * 100 - x;
        }
        if (resizing.direction.includes("s")) {
          height = ((e.clientY - canvas.top) / canvas.height) * 100 - y;
        }
        if (resizing.direction.includes("w")) {
          const newX = ((e.clientX - canvas.left) / canvas.width) * 100;
          width += x - newX;
          x = newX;
        }
        if (resizing.direction.includes("n")) {
          const newY = ((e.clientY - canvas.top) / canvas.height) * 100;
          height += y - newY;
          y = newY;
        }

        if (
          resizing.element.type === "image" ||
          resizing.element.type === "qrcode" ||
          resizing.element.type === "graphic"
        ) {
          if (resizing.direction.includes("n") || resizing.direction.includes("s")) {
            width = height * aspectRatio;
          } else {
            height = width / aspectRatio;
          }
        }

        onElementModify({
          ...resizing.element,
          x: Math.max(0, x),
          y: Math.max(0, y),
          width: Math.max(5, Math.min(width, 100 - x)),
          height: Math.max(5, Math.min(height, 100 - y)),
        });
      }
    };

    const handleMouseUp = () => {
      setDragging(null);
      setResizing(null);
    };

    if (dragging || resizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, resizing, onElementModify]);

  const handleDragStart = (e, element) => {
    if (isPreviewMode) return;
    const canvas = canvasRef.current.getBoundingClientRect();
    setDragging({
      element,
      offsetX: e.clientX - canvas.left - (element.x * canvas.width) / 100,
      offsetY: e.clientY - canvas.top - (element.y * canvas.height) / 100,
    });
  };

  const handleResizeStart = (e, element, direction) => {
    e.stopPropagation();
    setResizing({ element, direction });
  };

  return (
    <div
      className={`design-canvas-container ${isPreviewMode ? "preview-mode" : ""}`}
      style={{ width: `${CANVAS_WIDTH}px`, height: `${CANVAS_HEIGHT}px` }}
    >
      <div
        className="design-canvas"
        ref={canvasRef}
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        <div className="background-image-container">
          {backgroundImage}
        </div>
        {elements.map((element) => (
          <div
            key={element.id}
            className={`canvas-element ${element.type}`}
            style={{
              left: `${element.x}%`,
              top: `${element.y}%`,
              width: `${element.width}%`,
              height: `${element.height}%`,
              position: 'absolute',
            }}
            onMouseDown={(e) => !isPreviewMode && handleDragStart(e, element)}
            onClick={() => !isPreviewMode && onElementSelect(element)}
          >
            {element.type === "text" && (
              <div
                style={{
                  fontFamily: element.fontFamily,
                  fontSize: `${element.fontSize}px`,
                  fontWeight: element.fontWeight,
                  fontStyle: element.fontStyle,
                  textDecoration: element.textDecoration,
                  verticalAlign: element.verticalAlign,
                  color: element.color,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {element.content}
              </div>
            )}
            {(element.type === "image" ||
              element.type === "qrcode" ||
              element.type === "graphic") && (
              <img
                src={element.src}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            )}
            {!isPreviewMode && (
              <>
                <div
                  className="resize-handle resize-handle-n"
                  onMouseDown={(e) => handleResizeStart(e, element, "n")}
                />
                <div
                  className="resize-handle resize-handle-ne"
                  onMouseDown={(e) => handleResizeStart(e, element, "ne")}
                />
                <div
                  className="resize-handle resize-handle-e"
                  onMouseDown={(e) => handleResizeStart(e, element, "e")}
                />
                <div
                  className="resize-handle resize-handle-se"
                  onMouseDown={(e) => handleResizeStart(e, element, "se")}
                />
                <div
                  className="resize-handle resize-handle-s"
                  onMouseDown={(e) => handleResizeStart(e, element, "s")}
                />
                <div
                  className="resize-handle resize-handle-sw"
                  onMouseDown={(e) => handleResizeStart(e, element, "sw")}
                />
                <div
                  className="resize-handle resize-handle-w"
                  onMouseDown={(e) => handleResizeStart(e, element, "w")}
                />
                <div
                  className="resize-handle resize-handle-nw"
                  onMouseDown={(e) => handleResizeStart(e, element, "nw")}
                />
                <button
                  className="remove-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onElementRemove(element.id);
                  }}
                >
                  ×
                </button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DesignCanvas;