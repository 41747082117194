import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './booklets-list.css';
import GetImage from '../ImageUploadComponent/get-image';

function BookletsList() {
  const navigate = useNavigate();

  const cardTypes = [
    { name: 'Matte Book Uncoated Booklets', image: 'main_matte_book_8.5-5.5_uncoated_long.webp' },
    { name: 'Dull Book with Satin AQ Booklets', image: 'dull-book-w-satin-aq-booklets.webp' },
    { name: 'Direct Mail Booklets', image: 'direct-mail-booklets.webp' },
    { name: 'Premium Opaque Uncoated Booklets', image: 'premium-opaque-uncoated-booklets.webp' },
    { name: 'Gloss Booklets', image: 'gloss-booklet.webp' },
    { name: 'Gloss Cover with AQ Booklets', image: 'gloss-cover-w-aq-booklets.webp'},
    { name: 'Direct Mail Tri-Fold Flyers and Brochures', image: 'direct-mail-flyers-and-brochures.webp' },
    { name: 'Tri-Fold Brochures', image: 'tri-fold-flyers-brochures.webp'},
    { name: 'Direct Mail Flyers and Brochures Coated', image: 'direct-mail-flyers-and-brochures-coated.webp'}
  ];

  const handleCardsClick = (name) => {
    const urlFriendlyName = name.toLowerCase().replace(/\s+/g, '-');
    navigate(`/booklets/${urlFriendlyName}`);
  };

  return (
    <motion.main
      className="cards-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className='h1'>Booklets</h1>
      <motion.div className="cards-grid">
        {cardTypes.map((cards, index) => (
          <motion.div 
            key={cards.name}
            className="cards-item" 
            onClick={() => handleCardsClick(cards.name)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleCardsClick(cards.name);
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: index * 0.1,
              ease: "easeOut"
            }}
          >
            <div className="cards-image">
              <GetImage folderName="Booklets" imageName={cards.image} />
            </div>
            <div className="cards-info">
              <h2 className="cards-name">{cards.name}</h2>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.main>
  );
}

export default BookletsList;