import React from 'react';
import { Link } from 'react-router-dom';
import './IpadContainer.css';
import { 
  FaFlag, FaTshirt, FaMugHot, FaCar, FaSign, FaEnvelope, FaCalendarAlt, 
  FaBookOpen, FaBookmark, FaClipboard, FaDoorOpen, FaFileAlt, FaTicketAlt, 
  FaTag, FaPencilAlt, FaMagnet, FaUtensils, FaStickyNote, FaMailBulk, 
  FaFileImage, FaFolder, FaAddressCard, FaTable, FaLightbulb, FaPencilRuler, 
  FaClipboardCheck, FaShippingFast, FaTools, FaPaintBrush, FaWrench, 
  FaSignature, FaImage, FaWindowMaximize, FaHatCowboy, 
  FaRunning, FaTrophy, FaKey, FaGlobe, FaShoppingBag, FaVolumeUp
} from 'react-icons/fa';
import { 
  BsFillCreditCard2FrontFill, BsCardHeading, BsFillJournalBookmarkFill, 
  BsFillFileEarmarkTextFill, BsFillEnvelopeFill, 
  BsFillSignpostFill, BsFillDisplayFill
} from 'react-icons/bs';
import { IoNewspaperOutline, IoShirtOutline } from 'react-icons/io5';
import { GiCoffeeCup } from 'react-icons/gi';

function IpadContainer() {
  const iconData = [
    { name: 'Banner', icon: <FaFlag />, link: '/bannerlist' },
    { name: 'Business Card', icon: <BsFillCreditCard2FrontFill />, link: '/business-card-list' },
    { name: 'Shirts', icon: <FaTshirt />, link: '/shirtslist' },
    { name: 'Mugs', icon: <FaMugHot />, link: '/mugs' },
    { name: 'Car Decals', icon: <FaCar />, link: '/car-decals' },
    { name: 'Traffic Signs', icon: <FaSign />, link: '/traffic-signs' },
    { name: 'Greeting Cards', icon: <BsCardHeading />, link: '/greeting-cards-list' },
    { name: 'Announcement Cards', icon: <IoNewspaperOutline />, link: '/announcement-cards-list' },
    { name: 'Brochures', icon: <FaEnvelope />, link: '/brochures-list' },
    { name: 'Booklets', icon: <FaBookOpen />, link: '/booklets-list' },
    { name: 'Bookmarks', icon: <FaBookmark />, link: '/bookmarks' },
    { name: 'Calendars', icon: <FaCalendarAlt />, link: '/calendars' },
    { name: 'Catalogs', icon: <BsFillJournalBookmarkFill />, link: '/catalogs' },
    { name: 'Counter Cards', icon: <FaClipboard />, link: '/counter-cards' },
    { name: 'Door Hangers', icon: <FaDoorOpen />, link: '/door-hangers' },
    { name: 'Flyers', icon: <FaFileAlt />, link: '/flyers' },
    { name: 'Envelopes', icon: <BsFillEnvelopeFill />, link: '/envelopes' },
    { name: 'Event Tickets', icon: <FaTicketAlt />, link: '/event-tickets' },
    { name: 'Hand Tags', icon: <FaTag />, link: '/hand-tags' },
    { name: 'Letterheads', icon: <FaPencilAlt />, link: '/letterheads' },
    { name: 'Magnets', icon: <FaMagnet />, link: '/magnets' },
    { name: 'Menus', icon: <FaUtensils />, link: '/menus' },
    { name: 'Notepads', icon: <FaStickyNote />, link: '/notepads' },
    { name: 'Postcards', icon: <FaMailBulk />, link: '/postcards' },
    { name: 'Posters', icon: <FaFileImage />, link: '/posters' },
    { name: 'Presentation Folders', icon: <FaFolder />, link: '/presentation-folders' },
    { name: 'NCR Forms', icon: <BsFillFileEarmarkTextFill />, link: '/ncr-forms' },
    { name: 'Social Cards', icon: <FaAddressCard />, link: '/social-cards' },
    { name: 'Table Tent', icon: <FaTable />, link: '/table-tent' },
    { name: 'Face Illuminated Channel Letters', icon: <FaLightbulb />, link: '/face-illuminated-channel-letters' },
    { name: 'Reverse Illuminated Channel Letters', icon: <FaLightbulb />, link: '/reverse-illuminated-channel-letters' },
    { name: 'Front/Back Lit Channel Letters', icon: <FaLightbulb />, link: '/front-back-lit-channel-letters' },
    { name: 'Content Creation Consulting', icon: <FaPencilRuler />, link: '/content-creation-consulting' },
    { name: 'Survey and Permitting', icon: <FaClipboardCheck />, link: '/survey-and-permitting' },
    { name: 'Graphic Design', icon: <FaPaintBrush />, link: '/graphic-design' },
    { name: 'Project Management', icon: <FaWrench />, link: '/project-management' },
    { name: 'Shipping and Storage', icon: <FaShippingFast />, link: '/shipping-and-storage' },
    { name: 'Installation', icon: <FaTools />, link: '/installation' },
    { name: 'Rigid Signs', icon: <BsFillSignpostFill />, link: '/rigid-signs' },
    { name: 'Wall Art', icon: <FaImage />, link: '/wall-art' },
    { name: 'Outdoor Banners', icon: <FaFlag />, link: '/outdoor-banners' },
    { name: 'Indoor Banners', icon: <FaFlag />, link: '/indoor-banners' },
    { name: 'Flags', icon: <FaFlag />, link: '/flags' },
    { name: 'Floor Graphics', icon: <FaSignature />, link: '/floor-graphics' },
    { name: 'Window Graphics', icon: <FaWindowMaximize />, link: '/window-graphics' },
    { name: 'Wall Decals', icon: <FaImage />, link: '/wall-decals' },
    { name: 'Indoor Banner Stands', icon: <BsFillDisplayFill />, link: '/indoor-banner-stands' },
    { name: 'Outdoor Banner Stands', icon: <BsFillDisplayFill />, link: '/outdoor-banner-stands' },
    { name: 'Table Covers', icon: <FaTable />, link: '/table-covers' },
    { name: 'Displays', icon: <BsFillDisplayFill />, link: '/displays' },
    { name: 'Hoodies', icon: <IoShirtOutline />, link: '/hoodies' },
    { name: 'Sweatshirts', icon: <FaTshirt />, link: '/sweatshirts' },
    { name: 'Polo Shirts', icon: <FaTshirt />, link: '/polo-shirts' },
    { name: 'Hats', icon: <FaHatCowboy />, link: '/hats' },
    { name: 'Activewear', icon: <FaRunning />, link: '/activewear' },
    { name: 'Jackets', icon: <FaTshirt />, link: '/jackets' },
    { name: 'Workwear & Uniforms', icon: <FaTshirt />, link: '/workwear-uniforms' },
    { name: 'Drinkware', icon: <GiCoffeeCup />, link: '/drinkware' },
    { name: 'Bags', icon: <FaShoppingBag />, link: '/bags' },
    { name: 'Decals', icon: <FaSignature />, link: '/decals' },
    { name: 'Trophies', icon: <FaTrophy />, link: '/trophies' },
    { name: 'Key Chains', icon: <FaKey />, link: '/key-chains' },
    { name: 'Outdoor Signs', icon: <FaGlobe />, link: '/outdoor-signs' },
    { name: 'Promotional Products', icon: <FaShoppingBag />, link: '/promotional-products' },
    { name: 'Audio/Visual', icon: <FaVolumeUp />, link: '/audio-visual' },
  ];

  return (
    <div className="ipad-container">
      <div className="icon-grid">
        {iconData.map((icon, index) => (
          <Link to={icon.link} key={index} className="icon">
            {icon.icon}
            <p>{icon.name}</p>
          </Link>
        ))}
      </div>
      <div className="home-button"></div>      
    </div>
  );
}

export default IpadContainer;