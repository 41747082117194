import React from 'react';
import {  useNavigate } from 'react-router-dom';
import './shirts.css';

// Import your shirt images (adjust paths as needed)
import shirt1 from './t-shirt1.jpg';
import shirt2 from './t-shirt2.jpg';
import shirt3 from './t-shirt3.jpg';
import shirt4 from './t-shirt4.jpg';
import shirt5 from './t-shirt5.jpg';
import shirt6 from './tsg.jpg';

function ShirtsList() {
  const navigate = useNavigate();

  const shirtTypes = [
    { name: 'Short sleeve T-Shirt', image: shirt1, type: 'short-sleeve' },
    { name: 'Long Sleeve T-shirt', image: shirt2, type: 'long-sleeve' },
    { name: 'Hoodie', image: shirt3, type: 'hoodie' },
    { name: 'Sweatshirt', image: shirt4, type: 'sweatshirt' },
    { name: 'Tank Top', image: shirt5, type: 'tank-top' },
    { name: 'Polo Shirt', image: shirt6, type: 'polo' },
  ];

  const handleShirtClick = (type) => {
    navigate(`/shirts/${type}`);
  };

  return (
    <main className="shirts-list-page">
      <h2>Select a Shirt Type</h2>
      <div className="shirts-grid">
        {shirtTypes.map(shirt => (
          <div key={shirt.name} className="shirt-item" onClick={() => handleShirtClick(shirt.type)}>
            <img src={shirt.image} alt={shirt.name} />
            <p>{shirt.name}</p>
          </div>
        ))}
      </div>
    </main>
  );
}

export default ShirtsList;