import React, { useState, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as IconsFA from 'react-icons/fa';
import * as IconsMD from 'react-icons/md';
import * as IconsGI from 'react-icons/gi';
import * as IconsBI from 'react-icons/bi';
import './GraphicsList.css';

function GraphicsList({ onGraphicSelect }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedCategories, setExpandedCategories] = useState({});

  // Function to clean icon names
  const cleanIconName = (name) => {
    return name.replace(/^(Fa|Md|Gi|Bi)/, '').replace(/([A-Z])/g, ' $1').trim();
  };

  // Combine all icons
  const allIcons = useMemo(() => ({ ...IconsFA, ...IconsMD, ...IconsGI, ...IconsBI }), []);

  // Categories with initial icons
  const categories = useMemo(() => ({
    shapes: [
      { name: 'Square', Icon: IconsFA.FaSquare },
      { name: 'Circle', Icon: IconsFA.FaCircle },
      { name: 'Triangle', Icon: IconsFA.FaCaretUp },
      { name: 'Diamond', Icon: IconsGI.GiDiamondHard },
      { name: 'Pentagon', Icon: IconsGI.GiPentacle },
      { name: 'Star', Icon: IconsFA.FaStar },
    ],
    icons: [
      { name: 'Home', Icon: IconsFA.FaHome },
      { name: 'User', Icon: IconsFA.FaUser },
      { name: 'Cog', Icon: IconsFA.FaCog },
      { name: 'Bell', Icon: IconsFA.FaBell },
      { name: 'Calendar', Icon: IconsFA.FaCalendar },
      { name: 'Envelope', Icon: IconsFA.FaEnvelope },
    ],
    colorIcons: [
      { name: 'Sun', Icon: IconsFA.FaSun, color: '#FFD700' },
      { name: 'Heart', Icon: IconsFA.FaHeart, color: '#FF4136' },
      { name: 'Leaf', Icon: IconsFA.FaLeaf, color: '#2ECC40' },
      { name: 'Star', Icon: IconsFA.FaStar, color: '#FF851B' },
      { name: 'Cloud', Icon: IconsFA.FaCloud, color: '#7FDBFF' },
      { name: 'Fire', Icon: IconsGI.GiFire, color: '#FF4136' },
    ],
  }), []);

  const handleGraphicSelect = (item) => {
    const svgString = encodeURIComponent(
      ReactDOMServer.renderToStaticMarkup(
        React.createElement(item.Icon, { size: 100, color: item.color })
      )
    );
    const dataUrl = `data:image/svg+xml,${svgString}`;
    
    onGraphicSelect({ 
      id: item.name,
      name: item.name,
      src: dataUrl,
      type: 'image'  // Add this line to specify the type
    });
  };

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({...prev, [category]: !prev[category]}));
  };

  const renderCategory = (category, title) => {
    const isExpanded = expandedCategories[category];
    let iconsToShow = isExpanded ? Object.entries(allIcons) : categories[category];
    
    // Filter icons based on category
    if (isExpanded) {
      switch(category) {
        case 'shapes':
          iconsToShow = iconsToShow.filter(([name]) => 
            name.includes('Square') || name.includes('Circle') || 
            name.includes('Triangle') || name.includes('Diamond') || 
            name.includes('Pentagon') || name.includes('Star')
          );
          break;
        case 'colorIcons':
          iconsToShow = iconsToShow.filter(([name]) => 
            name.includes('Sun') || name.includes('Heart') || 
            name.includes('Leaf') || name.includes('Star') || 
            name.includes('Cloud') || name.includes('Fire')
          );
          break;
        case 'icons':
          // 'icons' category doesn't need filtering as it includes all icons
          break;
        default:
          // If an unknown category is provided, don't filter
          console.warn(`Unknown category: ${category}`);
          break;
      }
    }
  
    const filteredIcons = (isExpanded ? iconsToShow.map(([name, Icon]) => ({ name, Icon })) : iconsToShow)
      .filter(icon => cleanIconName(icon.name).toLowerCase().includes(searchTerm.toLowerCase()))
      .slice(0, isExpanded ? 100 : 6);
  
    return (
      <div key={category} className="icon-category">
        <h4>{title}</h4>
        <div className="graphics-grid">
          {filteredIcons.map((item, index) => (
            <div key={index} className="graphic-item" onClick={() => handleGraphicSelect(item)}>
              <item.Icon size={50} color={category === 'colorIcons' ? item.color : undefined} />
              <p>{cleanIconName(item.name)}</p>
            </div>
          ))}
        </div>
        <button onClick={() => toggleCategory(category)}>
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      </div>
    );
  };

  return (
    <div className="graphics-list">
      <h3>Choose a Graphic</h3>
      <input
        type="text"
        placeholder="Search icons..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      {renderCategory('shapes', 'Shapes')}
      {renderCategory('icons', 'Icons')}
      {renderCategory('colorIcons', 'Color Icons')}
    </div>
  );
}

export default GraphicsList;